/*** 

====================================================================
Products Section
====================================================================

***/

.products-section {
  position: relative;
  padding: 100px 0 70px;
}

.product-block {
  position: relative;
  margin-bottom: 30px;
}

.product-block .inner-box {
  position: relative;
  overflow: hidden;
  padding: 25px 30px 25px;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #ecedf2;
  text-align: center;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block .inner-box:hover {
  -webkit-box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
  box-shadow: 0px 6px 15px rgba(64, 79, 104, 0.05);
}

.product-block .image-box {
  position: relative;
}

.product-block .image {
  position: relative;
  overflow: hidden;
  margin-bottom: 25px;
}

.product-block .image img {
  display: inline-block;
  max-width: 100%;
}

.product-block .info {
  position: relative;
}

.product-block h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202124;
  margin-bottom: 7px;
}

.product-block .price {
  position: relative;
  font-size: 15px;
  line-height: 20px;
  display: block;
  color: white;
}

.product-block .theme-btn {
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}

.product-block .theme-btn i {
  margin-right: 10px;
}

.product-block .inner-box:hover .theme-btn {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}
